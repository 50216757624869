@media print {
    a[href]:after {
        content: none !important;
    }

    #top,
    .l-navigation,
    .breadcrumbs,
    .page.messages {
        display: none !important;
    }

    .label {
        border: none !important;
    }

    .order-links {
        .item {
            width: auto !important;
        }
    }

    .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
        padding: 5px !important;
    }

    .order-details-items {
        .order-title {
            >strong {
                margin-top: 0 !important;
            }
        }

        tfoot {
            th, td {
                padding: 5px !important;
            }
        }
    }

    .box.col-sm-6 {
        width: 50%;
        float: left;
    }
}